.main_container {
  width: 1024px;
  margin: 0 auto;
  text-align: left;
}

@media (max-width: 768px) {
  .main_container {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
}
