.container {
  width: 1024px;
  margin: 0 auto;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    margin: 0 auto;
  }
}
